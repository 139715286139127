import { sendEvent } from "src/analytics/sendEvent";
import { CachedAutocompleteApi } from "./CachedAutocompleteApi";
import { getAutocompleteHint } from "./getAutocompleteHint";
import { isPrintableCharacter } from "./isPrintableCharacter";
import type { Input, Place } from "./place-autocomplete.marko";
import type { LanguageCode } from "src/utils/language";

const latestQueryIds = new Map<symbol, number>();

export interface State {
  inputValue: string;
  inputId: symbol;
  currentQueryId: number;
  isAutocompleteListOpen: boolean;
  isEligbleForInlineSuggestion: boolean;
  highlightedResult: number;
  autocompleteResults: Place[];
  languageCode: LanguageCode;
  clientApiHost: string;
}

export default class extends Marko.Component<Input, State> {
  onCreate(input: Input, out: Marko.Out) {
    this.state = {
      inputValue: input.place.longName ?? input.place.shortName,
      inputId: Symbol(),
      currentQueryId: 1,
      highlightedResult: 0,
      isAutocompleteListOpen: false,
      isEligbleForInlineSuggestion: true,
      autocompleteResults: [],
      languageCode: out.global.languageCode as LanguageCode,
      clientApiHost: out.global.clientApiHost as string,
    };
  }

  onUpdate() {
    // Once the DOM has been updated, we need to highlight the field.
    const highlightedPlace = this.getHighlightedPlace();
    if (highlightedPlace && this.state.highlightedResult === 0) {
      const inputElement = this.getEl("input") as HTMLInputElement;
      window.requestAnimationFrame(() => {
        inputElement.setSelectionRange(
          this.state.inputValue.length,
          this.getInputValueWithHint().length,
        );
      });
    }
  }

  onInput(input: Input) {
    this.state.inputValue = input.place.longName ?? input.place.shortName;
  }

  onSearchBoxInput(event: InputEvent, element: HTMLInputElement) {
    const query = element.value;
    this.state.inputValue = query;

    if (query.length > 1) {
      this.fetchAutocompleteResults(query);
    } else {
      this.state.isAutocompleteListOpen = false;
    }
  }

  onInputKeyDown(event: KeyboardEvent) {
    this.handleVisualFocus(event);
    this.handleInlineSuggestion(event);

    switch (event.key) {
      case "Down":
      case "ArrowDown":
        this.maybeOpenAutocompleteList();
        break;
      case "Tab":
      case "Enter":
        const selectedPlace = this.getHighlightedPlace();
        if (selectedPlace) {
          this.onAutocompleteSelected(
            selectedPlace,
            "Enter",
            this.state.highlightedResult - 1,
          );
        }
        break;
      case "Esc":
      case "Escape":
        if (this.state.isAutocompleteListOpen) {
          // If the listbox is open, close it.
          this.state.isAutocompleteListOpen = false;
        } else {
          // Otherwise, clear the input.
          this.state.inputValue = "";
        }
        break;
    }
  }

  handleInlineSuggestion(event: KeyboardEvent) {
    if (isPrintableCharacter(event.key)) {
      this.state.isEligbleForInlineSuggestion = true;
    } else if (event.key === "Backspace") {
      this.state.isEligbleForInlineSuggestion = false;
    } else if (
      event.key === "Down" ||
      event.key === "ArrowUp" ||
      event.key === "Up" ||
      event.key === "ArrowDown"
    ) {
      this.state.isEligbleForInlineSuggestion = true;
    }
  }

  handleVisualFocus(event: KeyboardEvent) {
    if (!this.state.isAutocompleteListOpen) {
      // Ignore visual focus when the autocomplete list is closed.
      return;
    }
    let nextHighlightedResult = this.state.highlightedResult;
    switch (event.key) {
      case "Down":
      case "ArrowDown":
        nextHighlightedResult++;
        break;
      case "Up":
      case "ArrowUp":
        // ArrowUp usually causes the cursor to move to the start of the input
        // which we do not want.
        event.preventDefault();
        nextHighlightedResult--;
        break;
    }
    if (nextHighlightedResult > this.state.autocompleteResults.length) {
      nextHighlightedResult = 0;
    } else if (nextHighlightedResult < 0) {
      nextHighlightedResult = this.state.autocompleteResults.length;
    }
    this.state.highlightedResult = nextHighlightedResult;
  }

  onSearchBoxFocused(event: FocusEvent, element: HTMLInputElement) {
    window.requestAnimationFrame(() => {
      element.setSelectionRange(0, element.value.length);
    });
    this.maybeOpenAutocompleteList();
  }

  onSearchBoxFocusOut(event: FocusEvent) {
    const selectedPlace = this.getHighlightedPlace();
    const inputElement = this.getEl("input") as HTMLInputElement;
    const placeIsSelected =
      selectedPlace &&
      (inputElement.value.localeCompare(
        selectedPlace.shortName,
        this.state.languageCode,
        {
          sensitivity: "base",
        },
      ) === 0 ||
        inputElement.value.localeCompare(
          selectedPlace.longName,
          this.state.languageCode,
          {
            sensitivity: "base",
          },
        ) === 0);

    // If input value matches the selected place then the user has kept the inline autocomplete suggestion.
    // If the input value is different it means the user has escaped the autocomplete suggestion
    // and the user inputted value should be maintained instead.
    if (placeIsSelected) {
      this.state.inputValue = selectedPlace.longName ?? selectedPlace.shortName;
      this.onAutocompleteSelected(
        selectedPlace,
        "Blur",
        this.state.highlightedResult - 1,
      );
    } else {
      this.state.isAutocompleteListOpen = false;
    }
  }

  onAutocompleteSelected(place: Place, method: string, index: number) {
    sendEvent({
      category: "SearchAutocomplete",
      action: "SelectMethod",
      label: method,
      isNonInteraction: true,
    });
    sendEvent({
      category: "SearchAutocomplete",
      action: "SelectItemIndex",
      label: `Index ${index ?? 0}`,
    });
    this.state.inputValue = place.longName ?? place.shortName;
    this.clearAutocompleteResults();
    this.emit("place-changed", place);
  }

  fetchAutocompleteResults(query: string) {
    if (query.length > 1) {
      const queryId = this.state.currentQueryId;
      this.state.currentQueryId++;
      CachedAutocompleteApi.search(
        this.state.clientApiHost,
        query,
        this.state.languageCode,
      )
        .then((response) => {
          const results = response.results.slice(0, 6);
          const latestQueryId = latestQueryIds.get(this.state.inputId) ?? 0;
          // Ignore autocomplete results if the user has highlighted a result
          // because we don't want to change the list out from underneath them.
          if (queryId > latestQueryId) {
            latestQueryIds.set(this.state.inputId, queryId);

            if (this.state.highlightedResult === 0) {
              this.state.autocompleteResults = results;
              this.maybeOpenAutocompleteList();
            }
          }
        })
        .catch((error) => {
          /* no-op */
        });
    }
  }

  clearAutocompleteResults() {
    this.state.isAutocompleteListOpen = false;
    this.state.highlightedResult = 0;
    this.state.autocompleteResults = [];
  }

  maybeOpenAutocompleteList() {
    if (this.isInputFocused() && this.state.autocompleteResults.length) {
      this.state.isAutocompleteListOpen = true;
    }
  }

  isInputFocused() {
    return document.activeElement === this.getEl("input");
  }

  getHighlightedPlace(): Place | undefined {
    return this.state.highlightedResult
      ? this.state.autocompleteResults[this.state.highlightedResult - 1]
      : this.state.autocompleteResults[0];
  }

  getInputValueWithHint(): string {
    const highlightedPlace = this.getHighlightedPlace();
    if (highlightedPlace && this.state.isAutocompleteListOpen) {
      const placeName = highlightedPlace.longName ?? highlightedPlace.shortName;

      if (this.state.highlightedResult === 0) {
        if (this.state.isEligbleForInlineSuggestion) {
          // Only show the inline hint for the first element, otherwise, show the
          // full selected place name.
          const remainingText = getAutocompleteHint(
            this.state.inputValue,
            placeName,
          );

          return remainingText
            ? this.state.inputValue + remainingText
            : this.state.inputValue;
        } else {
          // Not currently eligble for an inline suggestion, so just give them the current
          // input value.
          this.state.inputValue;
        }
      } else {
        return placeName;
      }
    }

    return this.state.inputValue;
  }
}
