import type { AutocompleteResponse } from "./AutocompleteResponse";
import type { LanguageCode } from "src/utils/language";

export const AutocompleteApi = {
  async search(
    hostname: string,
    query: string,
    languageCode: LanguageCode,
  ): Promise<AutocompleteResponse> {
    const urlBuilder = new URL("/api/1.6/autocomplete", hostname);
    urlBuilder.searchParams.set("key", "oK8vkE5x");
    urlBuilder.searchParams.set("query", query);
    urlBuilder.searchParams.set("languageCode", languageCode);
    const requestURL = urlBuilder.href;

    const response = await fetch(requestURL);
    if (!response.ok) {
      throw new Error(
        `Error retrieving autocomplete response: ${response.status} ${response.statusText}`,
      );
    }
    const bodyObject: AutocompleteResponse = await response.json();
    return bodyObject;
  },
};
