import { messages } from "../../messages";
import type { Trigger } from "src/analytics/generateExitPoint/compressTrigger";
import { buildExplorePathname } from "src/url/buildExplorePathname";
import { buildHotelsRedirectUrl } from "src/popunder/urlSelector";
import { selectPopunderEligibility } from "src/popunder/Popunder";
import { generateNavigationEventId } from "src/analytics/generateNavigationEventId/generateNavigationEventId";
export interface Input {
  originCanonicalName: string;
  destinationCanonicalName: string;
  eventCategory: string;
  hasBeenModified: boolean;
  class?: Marko.HTMLAttributes["class"];
  icon: Marko.AttrTag<{ renderBody: Marko.Body }>;
  textClass?: Marko.HTMLAttributes["class"];
  label?: string;
}

$ {
  const exploreUrl = buildExplorePathname(
    $global.languageCode,
    input.originCanonicalName,
    input.destinationCanonicalName,
  );
}

<exit-link
  class=[
    "flex h-11 items-center justify-center rounded-lg bg-rome2rio-pink transition-opacity hover:opacity-90",
    input.class,
  ]
  href=exploreUrl
  method="Popunder"
  view=input.eventCategory
  trigger="TransportSearch"
  originCanonicalName=input.originCanonicalName
  destinationCanonicalName=input.destinationCanonicalName
  data-log="on-click"
  data-category=input.eventCategory
  data-action="Transport:Search"
  data-label=(input.hasBeenModified ? "Modified" : "NotModified") +
    (input.label ? `:${input.label}` : "")
  role="button"
  tabindex="0"
  id="search-button"
>
  <span class="relative"><${input.icon} /></span>
  <span
    class=[
      "text-base font-medium tracking-[.05em] text-white ml-2",
      input.textClass,
    ]
  >
    <formatted-message message=messages.searchButtonLabel no-update />
  </span>
</exit-link>

// Test the inline fallback popunder JavaScript in the Enabled variant of the A2BOOOR experiment.
// Only generate inline fallback code for popunder if popunder is eligible to be enabled.
<if(
  $global.backendFeatureConfig.ClientReorderA2BLP &&
    selectPopunderEligibility(
      $global.request,
      $global.backendFeatureConfig.PopunderRateLimit,
    ) === "Enabled",
)>
  $ const trigger: Trigger = "TransportSearch";
  $ {
    const options = {
      view: input.eventCategory,
      trigger: trigger,
      requestId: $global.r2rUserContext.requestId,
      originCanonicalName: input.originCanonicalName,
      destinationCanonicalName: input.destinationCanonicalName,
      languageCode: $global.languageCode,
      navigationEventId: generateNavigationEventId(),
    };
  }
  $ const popunderUrl = buildHotelsRedirectUrl(options);

  <script>
    document
      .getElementById("search-button")
      .addEventListener("click", function (event) {
        // Stop the event from triggering the anchor tag because we're handling it with the popunder.
        event.preventDefault();
        if (window.isPopunderHydrated === undefined) {
          // Try and open the destinationUrl in a new tab.
          const destinationUrl = "${exploreUrl}";
          const popoverWindow = window.open(destinationUrl, "_blank");
          if (!popoverWindow) {
            // We couldn't open a popover, so just update the current location
            // and early return.
            window.location.href = destinationUrl;
            return;
          }
          try {
            // Some pop-up blockers work by overriding window.open and causing .focus() to error.
            // So, we try and focus the popover window. This will throw an error if a pop-up blocker
            // has overwridden the focus method.
            popoverWindow.focus();
            // Other pop-up blockers open the new window then immediately close it.
            // So, we wait and check to see if the tab was closed.
            setTimeout(function () {
              const popoverBlocked = popoverWindow.closed;
              if (popoverBlocked) {
                // Navigate the current tab to the popoverUrl because the new tab
                // was blocked.
                // sendEvent(failureEvent);
                window.location.href = destinationUrl;
              } else {
                // Show the popunder because we were able to successfully open the destination
                // in a new tab and focused it.
                // sendEvent(successEvent);
                window.location.href = "${popunderUrl}";
              }
            }, 150);
          } catch (e) {
            // sendEvent??: should it be called?
            // sendEvent(failureEvent);
            // Something went wrong trying to open the destination URL in a new tab.
            window.location.href = destinationUrl;
          }
        }
      });
  </script>
</if>
