import { AutocompleteApi } from "src/api/AutocompleteApi/AutocompleteApi";
import type { AutocompleteResponse } from "src/api/AutocompleteApi/AutocompleteResponse";
import type { LanguageCode } from "src/utils/language";

// If the user changes the input quickly it's possible to get multiple requests
// for the same query. To avoid duplicating requests we keep a cache of inflight
// requests and join them if the same query is requested again.
type InflightRequestCache = Partial<
  Record<string, Promise<AutocompleteResponse>>
>;
const inflightRequests: InflightRequestCache = {};

export const CachedAutocompleteApi = {
  async search(
    hostname: string,
    query: string,
    languageCode: LanguageCode,
  ): Promise<AutocompleteResponse> {
    const inflightRequest = inflightRequests[query];
    if (inflightRequest) {
      return inflightRequest;
    }

    try {
      const result = AutocompleteApi.search(hostname, query, languageCode);
      inflightRequests[query] = result;
      return await result;
    } catch (error) {
      // We don't want to cache errors since it means that the user will never
      // be able to retry that particular request.
      inflightRequests[query] = undefined;
      throw error;
    }
  },
};
